@tailwind base;
@tailwind components;
@tailwind utilities;

#cloud-circle {
    width: 800px; /* Increase width */
    height: 500px; /* Increase height */
    background: #000;
    border-radius: 50%;
    filter: url(#filter);
    box-shadow: 800px 800px 100px 0px #fff; /* Adjust shadow */
    position: fixed; /* Take the parent out of the document flow */
    top: -700px; /* Adjust position */
    left: -800px; /* Adjust position */
    z-index: 0; /* Behind the content */
  }


  html {
    scroll-behavior: smooth;
  }

    /* For Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
  z-index: 50;
}

::-webkit-scrollbar-track {
  background: transparent; /* No background for the track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.474); /* Gray color for the scrollbar thumb */
  border-radius: 10px; /* Make it rounded */
  border: 3px solid transparent; /* Add transparent border for some padding */
  z-index: 50;
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: gray transparent; /* Gray thumb with no background */
}
